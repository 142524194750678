
.ant-form-item {
    margin-bottom: 10px;
  }
.ant-form-item label, .ant-form-item .ant-form-item-label >label{
    color: var(--txt-color);    
    font-size: 16px;
    font-weight: 400;
} 

.ant-card {    
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%) !important;
}

.ant-drawer-close {
    display: none !important;
}

/* .ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 10px 10px !important;
} */

/* .table {
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
} */

.table thead th {
    border-bottom: 1px solid #e2e5e8;
    font-size: 13px;
    color: #37474f;
    /* background: #ecf0f5 !important; */
    text-transform: uppercase;
}

.table td, .table th {
    border-top: 1px solid #e2e5e8;
    white-space: nowrap;
    padding: 1.05rem 0.75rem;
}

.ant-card-body {
    padding: 14px !important;
}

.theme-mode-dark .ant-input, .theme-mode-dark .ant-input-password {
    background-color: var(--main-bg) !important;
    color: var(--txt-color) !important;
    
    border-color: darkslategrey !important;
}

.theme-mode-dark .ant-input-password-icon {
    color: var(--txt-color) !important;
}

.theme-mode-dark .ant-select-selector, .theme-mode-dark .ant-select-arrow {
    background-color: var(--main-bg) !important;
    color: var(--txt-color) !important;    
    border-color: darkslategrey !important;
}

.theme-mode-dark .ant-table {
    color: var(--txt-color);
}

.theme-mode-dark .ant-table-thead >tr>th {
    background : var(--main-bg);
    color: var(--txt-color);
}

.theme-mode-dark .ant-table-tbody >tr>td {
    background : var(--main-bg);
    color: var(--txt-color);
}